import React from 'react'
import HomeLeft from '../home/home_left/HomeLeft'
import SubUserRight from './SubUserRight'
import { useAuthContext } from '../../context/AuthContext'
import NotificationError from '../NotificationError'

export default function UserSub() {
    const {isAuthenticated} = useAuthContext()
    if(!isAuthenticated) return (
        <NotificationError text={'Hãy đăng nhập hoặc đăng ký để có thể tiếp tục.'}></NotificationError>
    )
    return (
        <div className='row' >
            <HomeLeft></HomeLeft>
            <SubUserRight/>
        </div>
    )
}
