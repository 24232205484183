
import React, { useState, useRef, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { PUBLISH_USER } from '../../../../../graphql-client/Mutation';
import { GET_AVATAR_USER_MANAGE } from '../../../../../graphql-client/Queries';
import useHover from '../useHover';
import { handleTextAreaChange, handleModalClose } from '../../../../../utils/FormUtils'
export default function FormTextProfile() {
  
  const [formState, setFormState] = useState({ text: '' });

  const [isOpen, onClose, handleButtonClick, handleMouseEnter, handleMouseLeave, handleResetButtonClick] = useHover();
  
  const [publishUser] = useMutation(PUBLISH_USER, {
    update: (cache, { data }) => {
      cache.writeQuery({
        query: GET_AVATAR_USER_MANAGE,
        data: {
          userManage: data.publishUser,
        },
      });
    },
  });

  const textAreaRef = useRef(null);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    publishUser({
      variables: {
        about: formState.text,
      },
    });
    handleResetButtonClick()
    alert("Đã thay đổi giới thiệu của Bạn ấn vào 'Thoát' để trở lại.");
  }, [formState.text, publishUser]);
 
const handleTextAreaChangeCallback = useCallback(handleTextAreaChange(setFormState), [setFormState]);
const handleModalCloseCallback = useCallback(handleModalClose(setFormState, onClose, handleResetButtonClick), [setFormState, onClose, handleResetButtonClick]);
 return (
  <div>
      <button 
          onClick={handleButtonClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="btn dropdown ms-auto" 
          data-bs-toggle="modal" 
          data-bs-target="#toolTextProfileModal" 
      >
          <i className="fas fa-pen" />
      </button>
      {
        isOpen === true &&
        <div className={'modal fade'} id="toolTextProfileModal" tabIndex={-1}  aria-hidden="false">
          <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="toolTextProfileModalLabel">
                    Chỉnh sửa phần giới thiệu của bạn
                  </h5>
                </div>
                <div className="modal-body">
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label htmlFor="introduceChannels-text" className="col-form-label">
                        Giới thiệu kênh:
                      </label>
                      <textarea
                        ref={textAreaRef}
                        className="form-control"
                        id="introduceChannels-text"
                        name="introduceChannels-text"
                        rows="4"
                        cols="50"
                        value={formState.text}
                        onChange={handleTextAreaChangeCallback}
                      />
                      
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" 
                       onClick={handleModalCloseCallback }
                       >
                        Thoát
                      </button>
                      <button type="submit" className="btn btn-primary" data-bs-dismiss="modal" >
                        Gửi
                      </button>
                    </div>
                  </form>
                </div>
            </div>
          </div>
        </div>
      }
  </div> 
 )
}

    
 