
import React from 'react'
import { NOTIFICATIONS } from '../../../../graphql-client/Queries'
import { useMutation, useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import { UPDATE_NOTIFICATION } from '../../../../graphql-client/Mutation'
export default function HeaderRightNotification() {
    const [updateNotification] = useMutation(UPDATE_NOTIFICATION)
    const {data, loading, error }= useQuery(NOTIFICATIONS)
    if(loading) return <p>Loading...</p>
    if(error) return <p>Error...</p>

    return (
        <div className="btn-group dropstart">
            <button 
                type="button" className="btn rounded p-0" 
                data-bs-toggle="dropdown" aria-expanded="false" 
                title="thông báo"
            >
                <div className="nav-link d-flex flex-row" >
                    <div className="bell-icon">
                        <i className="far fa-bell"></i>
                    </div>
                    {
                        data 
                        && data.userManage.notifications.filter(e=>e.read === false).length !==0 
                        && <span className="badge bg-primary">
                                {data.userManage.notifications.filter(e=>e.read === false).length}
                            </span>
                    }
                </div>
            </button>
            <ul className="dropdown-menu notification " > 
                {
                    data 
                    && data.userManage.notifications.length !== 0 
                    ? data.userManage.notifications.map((notification)=>{
                        return(
                                <li key={notification.id}>
                                    <Link 
                                        to={notification.video
                                        ?"/watching/" + notification.video.id
                                        :"#"} 
                                        className={notification.read
                                            ?"notificationText mb-1 d-flex flex-row"
                                            :"newNotification mb-1 d-flex flex-row"}
                                        onClick={() => updateNotification({ 
                                            variables: { notificationId: notification.id } ,
                                            update(cache, {data:{updateNotification}}){
                                                const notifications = cache.readQuery({
                                                    query: NOTIFICATIONS
                                                })
                                                const updatedSentNotifications = notifications.userManage.notifications.map(notification => {
                                                    if (notification.id === updateNotification.id) {
                                                        return updateNotification;
                                                    } else {
                                                        return notification;
                                                    }
                                                    });
                                                cache.writeQuery({
                                                    query: NOTIFICATIONS,
                                                    data:{
                                                        userManage: {
                                                            ...notifications.userManage,
                                                            notifications: updatedSentNotifications
                                                            }
                                                    }
                                                })
                                            }
                                        })}
                                    >
                                        <div className="d-flex align-items-center">
                                        {/* {!notification.read&&<span className="dot me-1 ms-1 mt-4"></span>} */}
                                            {notification.video 
                                                && <img className="img-video_avatar m-1 " 
                                                        alt="" src={notification.video.thumbnail} 
                                                    />
                                            }
                                            <p className='m-1 p-1'>
                                                {notification.text}
                                            </p>
                                        </div>
                                            
                                    </Link >
                                </li>
                            )
                        })
                    : 
                    <li>
                        <div className="nameCommented d-flex">
                                <p>
                                    Bạn không có thông báo nào.
                                </p>
                        </div>
                    </li>
                }
            </ul>
        </div>
    )
}
