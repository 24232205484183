import React from 'react'
import { useMutation} from '@apollo/client';
import { DELETE_VIDEO, DELETE_VIDEO_LIST } from '../../../../../../graphql-client/Mutation';
import { LIST_VIDEO_USER_MANAGE, SHOW_VIDEO_LIST } from '../../../../../../graphql-client/Queries';

export default React.memo(function FormUpdateVideo(props) {
      const [deleteVideo] = useMutation(
            DELETE_VIDEO, 
            {
                variables: {
                id: props.videoId,
                },
                update:(cache, {data})=>{
                    cache.modify({
                        query: LIST_VIDEO_USER_MANAGE,
                        id: cache.identify({ __typename: 'VideoList', id: props.videoListId }),
                        fields: {
                          countVideo(existingCountVideoRefs, { readField }) {
                            return existingCountVideoRefs -1;
                          },
                          video(existingVideosRefs, { readField }) {
                            return existingVideosRefs.filter(
                              videoRef => readField('id', videoRef) !== props.videoId
                            );
                          },
                        },
                      });
                    cache.modify({
                        query: SHOW_VIDEO_LIST,
                        fields: {
                            showVideoList(existingVideosRefs, { readField }) {
                            return existingVideosRefs.filter(
                              videoRef => readField('id', videoRef) !== props.videoId
                            );
                          },
                        },
                      });
                }
            }
        );
      
      const [deleteVideoList] = useMutation(
            DELETE_VIDEO_LIST,
            {
                variables: {
                id: props.videoListId,
                },
                update:(cache, {data})=>{
                    cache.modify({
                        query: LIST_VIDEO_USER_MANAGE,
                        fields: {
                          listVideoUserManage(existingVideoRefs, { readField }) {
                            return existingVideoRefs.filter(
                              (videoList) => readField('id', videoList) !== props.videoListId
                            );
                          },
                        },
                      });
                }
            }
        );

      const deleteVersion = ()=>{
            props.deleteVideo
            ?  deleteVideo()
            : deleteVideoList()
        }
    return (
        <div>          
            <div className="modal-content ">
                <div className="modal-header d-flex justify-content-between">
                    <h5 className="modal-title" id="newVideoModalModalLabel">
                        {
                            props.publishVideoManager
                            ?
                            "Chỉnh sửa Khóa học của bạn"
                            :
                            "Chỉnh sửa Video của bạn"
                        }
                    </h5>
                    <i className="fa fa-times" aria-hidden="true" data-bs-dismiss="modal"></i>
                </div>
                <div className="modal-body d-flex  justify-content-between">    
                        <div>
                            {
                                props.publishVideoManager
                                ?
                                "Bạn có chắc chắn muốn xóa Khóa học này?"
                                :
                                "Bạn có chắc chắn muốn xóa Video này?"
                            }
                        </div>
                        <button 
                            className='btn btn-secondary' data-bs-dismiss="modal"
                            onClick={()=>{
                                deleteVersion();
                                }
                            } 
                        >
                            Có
                        </button>
                </div>
            </div>
        </div>
    )
}
)