import { Link, useHistory } from 'react-router-dom';
import React from 'react'
import { useAuthContext } from '../../../../context/AuthContext';
import { useMutation } from '@apollo/client';
import { LOGOUT } from '../../../../graphql-client/Mutation';
import JWTManager from '../../../../utils/jwt'
import { useApolloClient } from '@apollo/client';
export default function HeaderRightUser() {
    const client = useApolloClient();
    const history = useHistory();
    
    const {isAuthenticated, logoutClient} = useAuthContext()

    const [logoutServer] = useMutation(
        LOGOUT,
        {variables: {userId: JWTManager.getUserId()}})


    const logout = async ()=>{
        logoutClient()
        
        await logoutServer()
        await client.clearStore()
    } 

    return (
        <div className="btn-group dropstart">
            <button type="button" className=" btn rounded me-3 " data-bs-toggle="dropdown" aria-expanded="false">
                <div className='bell-icon'>
                    <i className="fas fa-user-alt"></i>
                </div>
            </button>
            <ul className="dropdown-menu index-notification">
                <li><Link className="dropdown-item" to="/manageUser">Tài khoản</Link></li>
                <li><Link className="dropdown-item" to="/feedback">Đóng góp ý kiến</Link></li>
                <li><hr className="dropdown-divider" /></li>
                <li>
                    <div className="flex flex-fixed ">
                            {(isAuthenticated) ? (
                            <div
                                className="ml1 pointer black"
                                onClick={() => {
                                logout();
                                history.push(`/`);
                                }}
                            >
                            <button className='dropdown-item '>
                            Đăng xuất
                            </button>
                            </div>
                            ) : (
                            <Link
                                to="/login"
                                className="dropdown-item"
                            >
                                Đăng nhập/ Đăng ký
                            </Link>
                            )}
                    </div>
                </li>
            </ul>
        </div>
    )
}


