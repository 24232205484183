import {  useMutation } from '@apollo/client'
import React from 'react'
import { PUBLISH_USER } from '../../../../../graphql-client/Mutation'
import { useState } from 'react'
import MakeImg from '../../../../../uploadToS3/MakeImg'
import { uploadImageToS3 } from '../../../../../uploadToS3/UploadImgToS3'

export default React.memo(function CoverForm(props) {
        const [coverForm, setCoverForm] = useState({ thumbnail:'',  });
        const [croppedImage, setCroppedImage] = useState(null);
        const [settingUser] = useMutation(PUBLISH_USER,{
          variables:{
            cover: coverForm.thumbnail,
          },
        });
        const handleSubmit = async (e) => {
          e.preventDefault();
          await uploadImageToS3(croppedImage, coverForm, setCoverForm)
          settingUser();
          setCoverForm({ thumbnail: ''})
          setCroppedImage(null)
        };
    return (
      <div> 
        <div className="form-group" >
          <div className='d-flex flex-column'>
              <label htmlFor="manageAvatar">
                <b className='m-1 p-1'>
                  Ảnh bìa
                </b>
              </label>
                <MakeImg formListVideo={coverForm} setFormListVideo={setCoverForm}
                         croppedImage={croppedImage} setCroppedImage={setCroppedImage}
                >
                </MakeImg>
                {croppedImage && (
                  <button onClick={handleSubmit}  className="btn btn-primary mx-auto">
                    Cập nhật
                  </button>
                )}
            <br />
            <div className='row col-xl-10 col-lg-10 col-md-10 col-sm-10 mx-auto'>
              <img src={props.coverManager} alt="a" />
            </div>
            <br />
          </div>     
        </div>
      </div> 
    )
})
