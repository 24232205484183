import React from 'react'
import ProfileUser from './connectMenuUser/ProfileUser'
import VideoListUser from './connectMenuUser/VideoListUser'

export default function BodyUser() {
    return (
        <div className="bodyUser">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button 
                            
                            className="nav-link active" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" 
                            type="button" role="tab" aria-controls="profile" aria-selected="true"
                    >
                        Giới thiệu
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button 
                            
                            className="nav-link" id="videoList-tab" data-bs-toggle="tab" data-bs-target="#videoList" 
                            type="button" role="tab" aria-controls="videoList" aria-selected="false"
                    >
                        Khóa Học
                    </button>
                </li>
            </ul>
            <div className="tab-content">
                <div className="tab-pane show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    <ProfileUser/>
                </div>
                
                <div className="tab-pane" id="videoList" role="tabpanel" aria-labelledby="videoList-tab">
                    <VideoListUser/>
                </div>
                
            </div>
        </div>
    )
}
