import React from 'react'
import HomeLeft from '../home/home_left/HomeLeft'
import VideoWatchedRight from './VideoWatchedRight'
import { useAuthContext
 } from '../../context/AuthContext'
import NotificationError from '../NotificationError'
export default function VideosLike() {
    const {isAuthenticated} = useAuthContext()
    if(!isAuthenticated) return (
        <NotificationError text ={'Hãy đăng nhập hoặc đăng ký để có thể tiếp tục.'}></NotificationError>
    )
    return (
        <div className='row' >
            <HomeLeft></HomeLeft>
            <VideoWatchedRight/>
        </div>
    )
}
