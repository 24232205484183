import React, { useState } from 'react';
import { uploadImageToS3 } from './UploadImgToS3';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

export default function ComponentUploadImgToS3({ formListVideo, setFormListVideo }) {
  const [isShowCrop, setIsShowCrop] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [crop, setCrop] = useState({
    unit: '%',
    width: 80,
    height: 80,
    aspect: 16 / 9,
    x: 7,
    y: 15,
  });

  const handleUpload = () => {
    const image = new Image();
    image.src = imageUrl;
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const cropX = crop.x * scaleX;
      const cropY = crop.y * scaleY;
      const cropWidth = crop.width * scaleX;
      const cropHeight = crop.height * scaleY;
      canvas.width = cropWidth;
      canvas.height = cropHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(
        image,
        cropX,
        cropY,
        cropWidth,
        cropHeight,
        0,
        0,
        cropWidth,
        cropHeight
      );
      canvas.toBlob((blob) => {
        const file = new File([blob], 'cropped-image.jpg', { type: 'image/jpeg' });
        uploadImageToS3(file, formListVideo, setFormListVideo);
        setIsShowCrop(false);
      }, 'image/jpeg');
    };
  };

  const handleFileSelect = (e) => {
    const selectedFile = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImageUrl(reader.result);

      // Check screen size and auto-upload if width < 1000px
      if (window.innerWidth < 1000) {
        const file = new File([selectedFile], 'image.jpg', { type: selectedFile.type });
        uploadImageToS3(file, formListVideo, setFormListVideo);
      } else {
        setIsShowCrop(true);
      }
    };
    reader.readAsDataURL(selectedFile);
  };

  return (
    <div className='p-2'>
      {!formListVideo.thumbnail ? (
        <div className="d-flex justify-content-between ">
          <input type="file" accept="image/*" onChange={handleFileSelect} multiple={false} />
        </div>
      ) : (
        formListVideo.thumbnail && (
          <div className="d-flex justify-content-start">
            <p className='me-3'>Đã tải ảnh lên</p>
            <img src={formListVideo.thumbnail} alt="uploaded" className="thumbnailOnForm" />
          </div>
        )
      )}
      {isShowCrop && (
        <div>
          <div className='d-flex justify-content-center m-2'>
            <button className='mx-auto' onClick={handleUpload}>Tải lên</button>
          </div>
          <div className=' d-flex justify-content-center'>
            <ReactCrop
              crop={crop}
              onChange={(newCrop) => setCrop(newCrop)}
              aspect={16 / 9}
            >
              <img src={imageUrl} alt="" className='img-crop' />
            </ReactCrop>
          </div>
        </div>
      )}
    </div>
  );
}
