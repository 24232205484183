import HeaderLeft from './Header_left/HeaderLeft'
import HeaderMid from './searchForm/HeaderMid'
import HeaderRight from './header_right/HeaderRight'
import React from 'react'

export default function Header() {
    return (
            <div className='atTop'>
                <ul className="nav navbar card-header-tabs bg-light d-flex justify-content-between align-items-center flex-nowrap mb-1">
                    <HeaderLeft></HeaderLeft>
                    <HeaderMid></HeaderMid>
                    <HeaderRight></HeaderRight>
                </ul>
            </div>    
        )
}


