import React from 'react'
import FormTextProfile from './formToolProfile/FormTextProfile'
import FormVideoProfile from './formToolProfile/FormVideoProfile'
import FormFromProfile from './formToolProfile/FormFromProfile'
import {GET_AVATAR_USER_MANAGE} from '../../../../graphql-client/Queries'
import { useQuery } from '@apollo/client'

export default React.memo(function ManageProfile() {

    const {loading, error, data} = useQuery(GET_AVATAR_USER_MANAGE);
    if(loading) return <p>loading...</p>;
    if(error) return `Bạn chưa đang nhập `;
    console.log("profile user manage");
    
    return (
        <div>
            <br />
            <div className="d-flex justify-content-between">
                <h5>
                    Giới thiệu kênh
                </h5>
                <FormTextProfile/>
            </div>
            
            {
                data.userManage.about
                ?<div className="text_per_line p-2">{data.userManage.about}</div>
                :<p>Hãy thêm thông tin về kênh của bạn.</p>
            }
            <hr />
            <div className="d-flex">
                <h5>
                    Video giới thiệu
                </h5>
                <button 
                    type="button" className="btn dropdown ms-auto" data-bs-toggle="modal" 
                    data-bs-target="#toolVideoProfileModal" data-bs-whatever><i className="fas fa-pen" 
                />
                </button>
                <FormVideoProfile></FormVideoProfile>
            </div>
                <video  controls className="videoAbout mx-auto">
                    <source src={data.userManage.videoAbout} type="video/mp4" />
                    <source src="dammio.ogg" type="video/ogg" />
                Trình duyệt của bạn không hỗ trợ thẻ video.
                </video>
                <hr />
            <div className="d-flex justify-content-between">
                <h5>
                    Địa chỉ liên hệ
                </h5>
                <br />
                <FormFromProfile ></FormFromProfile>
            </div>
            {
                data.userManage.info
                ?<p className="text_per_line p-2">{data.userManage.info}</p>
                :<p>Chưa có thông tin liên lạc. Hãy thêm thông tin liên lạc của bạn.</p>
            }
            
        </div>
    );
}
)