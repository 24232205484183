
import React from 'react'
import { useQuery } from '@apollo/client';
import {useParams} from "react-router-dom";
import {getDateIntVideo} from '../../../../graphql-client/Queries'
import moment from 'moment';
import ButtonLikeVideo  from './buttonLikeVideo/ButtonLikeVideo';
import { FacebookShareButton, FacebookIcon, RedditIcon, RedditShareButton, WhatsappShareButton, WhatsappIcon } from 'react-share';
import { useAuthContext } from '../../../../context/AuthContext';
export default React.memo(function CardIntVideoPlaying() {
    let {id} = useParams();
    const {isAuthenticated} = useAuthContext()
    const {loading, error, data} = useQuery(getDateIntVideo,{
        variables: {id},
    });
    if(loading) return <p>loading...</p>;
    if (error) return `Error! ${error.message}`;
    return (
        <div className="p-2">
            <div className='d-flex flex-column'>
                <h4 className='ps-2'>
                    {data.video.title}
                </h4>
                {data.video.tags && <p className='p-2 mb-0'>{data.video.tags}</p>}
            </div>
            <div className="intVideo d-flex justify-content-between">

                <div className="statisticalVideo d-flex flex-row ">
                    <div className=' d-flex  p-2 pe-0'>
                        <div className="d-flex pe-1">
                            <p>
                                {data.video.countView}
                            </p>
                            &nbsp;
                            <p>
                                views
                            </p>
                        </div>
                        <div className='pe-1'>
                            •
                        </div>
                    </div>
                    <div className="d-flex flex-row p-2 ps-0">
                        <p>
                            {moment(data.video.createdAt).fromNow() }
                        </p>
                    </div>
                </div>
                <div className="iconVideoPlaying d-flex flex-md-row flex-column">
                    <div className="iconLikeVideo  d-flex mb-2 mx-auto me-1">
                        {
                            !isAuthenticated
                            ?<div className="m-auto me-1 ms-1">
                                <i className='fas fa-heart'></i>
                            </div>
                            :<ButtonLikeVideo 
                                countLike={data.video.countLike}
                            >
                            </ButtonLikeVideo>
                            
                        }
                        
                        <div className="d-flex flex-column likeTextVideo d-inline-flex ms-1">
                            <p>
                                {data.video.countLike}
                            </p>
                            <p>
                                Like
                            </p>
                        </div>
                    </div>
                    <div className="shareVideo  d-flex me-1 ">
                        <i className="fas fa-share" title='Chia sẻ' />
                        <FacebookShareButton url={'https://linklearn.vn/watching/'+data.video.id} title='facebook'>
                            <FacebookIcon  size={30} round={true}/>
                        </FacebookShareButton>
                        <RedditShareButton url={'https://www.makeuseof.com/add-social-share-buttons-in-react/'} title='Reddit'>
                            <RedditIcon  size={30} round={true} />
                        </RedditShareButton>

                        <WhatsappShareButton className='me-1' url={'https://www.makeuseof.com/add-social-share-buttons-in-react/'} title='Whatsapp'>
                            <WhatsappIcon  size={30} round={true}/>
                        </WhatsappShareButton>
                    </div>       
                </div>  
            </div>
        </div>
    )
}
)

