import React from 'react'
import HeaderRightNotification from './Header_right_notification/HeaderRightNotification'
import HeaderRightUser from './Header-right-user/HeaderRightUser'
import { useAuthContext } from '../../../context/AuthContext'
export default function HeaderRight() {
    const {isAuthenticated} = useAuthContext()
    const  notificationNoUser = ()=>{
        alert('Bạn chưa đăng nhập')
    }
    return (
        <li className="nav-item ms-auto me-1">
            <div className="  bg-light ">
                <div className="nav navbar-expand " id="collapsibleNavId">
                    <ul className="navbar-nav  navbar-expand-sm ms-auto ">
                        <li>
                            {
                                isAuthenticated
                                ?<HeaderRightNotification></HeaderRightNotification>
                                :<div className="nav-link d-flex flex-row" onClick={notificationNoUser} >
                                    <div className="bell-icon">
                                        <i className="far fa-bell"></i>
                                    </div>
                                </div>
                            }
                        </li>
                        <li>
                            <HeaderRightUser></HeaderRightUser>
                        </li>
                    </ul>
                </div>
            </div>
        </li>
        
    )
}


