import React,{useState} from 'react'
import { useMutation } from '@apollo/client';
import { PUBLISH_USER } from '../../../../../graphql-client/Mutation';
import { GET_AVATAR_USER_MANAGE } from '../../../../../graphql-client/Queries';

import ComponentUploadVideo from '../../../../../uploadToS3/ComponentUploadVideo';
import { UploadVideoToS3 } from '../../../../../uploadToS3/UploadVideoToS3';
export default function FormVideoProfile() {
    const [formState, setFormState] = useState({ url: '', thumbnail:'' });
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [process, setProcess] = useState(0);

    const [publishUser] = useMutation(PUBLISH_USER, {
      variables: {
        videoAbout: formState.url,
      },
        update: (cache, { data }) => {
          cache.writeQuery({
            query: GET_AVATAR_USER_MANAGE,
            data: {
              userManage: data.publishUser,
            },
          });
        },
      });
      const handleSubmit = async (e) => {
        e.preventDefault();
        await UploadVideoToS3(file, formState, setFormState, setIsLoading, setProcess);
        publishUser()
        alert("Đã thay đổi video giới thiệu.");
      }
    return (
        <div className="modal fade" id="toolVideoProfileModal" tabIndex={-1} aria-labelledby="toolVideoProfileModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="toolVideoProfileModalLabel">
                            Chỉnh sửa Video giới thiệu của bạn.
                        </h5>
                    </div>
                    <div className="modal-body">
                        <ComponentUploadVideo isLoading={isLoading} process={process} setFile={setFile} formState={formState} ></ComponentUploadVideo>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Thoát</button>
                            <button type="button" className="btn btn-primary" onClick={handleSubmit} >Cập nhật</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
