
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter} from "react-router-dom";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider, 
  createHttpLink,
  split,
  } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import config from './constants';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import JWTManager from './utils/jwt.js'

import { CloudinaryContext } from "cloudinary-react";
import AuthContextProvider from './context/AuthContext';
import { offsetLimitPagination} from "@apollo/client/utilities";

// Phần mềm chung gian gắn token vào mọi res đến máy chủ
const authLink = setContext((_, { headers }) => {
  // Lấy token trong biến của js 
  const token = JWTManager.getToken();
  
  return {
    // Gắn token vào headers của res
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const linkChain = createHttpLink({
  uri:config.URL_WEB_SERVER,
  credentials: 'include'
});
const wsLink = new WebSocketLink({

  uri: config.URL_WEB_SERVER_REALTIME,
  options: {
    reconnect: true,
    connectionParams: {
      authToken: config.AUTH_TOKEN
    }
  }
});
const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(linkChain)
);


const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          videosRecommended: offsetLimitPagination(),
          commentsVideo: offsetLimitPagination(),
          showVideoList: offsetLimitPagination(),
          videosLike: offsetLimitPagination(),
          videosWatched: offsetLimitPagination(),
          listVideoUser: offsetLimitPagination(
            ["idUser"], ["VideoList"], { 
              mergeMode: "merge",
              keyArgs: ["idUser"], // Sử dụng tham số "take" của truy vấn làm key
            }
          ),
          listVideoUserManage: offsetLimitPagination(
             ["VideoList"], { 
            mergeMode: "merge",
            keyArgs: false, // Sử dụng tham số "take" của truy vấn làm key
            merge(existing, incoming, { args: { offset = 0 }}) {
              // Slicing is necessary because the existing data is
              // immutable, and frozen in development.
              const merged = existing ? existing.slice(0) : [];
              for (let i = 0; i < incoming.length; ++i) {
                merged[offset + i] = incoming[i];
              }
              return merged;
            },
          }
          ),
          
        },
      },
    },
  }),
  link,
});

ReactDOM.render(
  <ApolloProvider client={client}>
        <React.StrictMode>
            <CloudinaryContext cloudName="nhk598" secure = " true ">
                <BrowserRouter>
                  <AuthContextProvider>
                     <App/>
                  </AuthContextProvider>
                </BrowserRouter>
            </CloudinaryContext>
        </React.StrictMode>
  </ApolloProvider>,
  
  document.getElementById('root')
);


reportWebVitals();
