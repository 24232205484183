import { useQuery } from '@apollo/client'
import { VIDEOS_RECOMMENDED } from '../../../graphql-client/Queries'
import VideoCard from '../../home/home_right/videoCard/VideoCard'
import React from 'react'

export default React.memo(function NextVideo(props) {
    const {loading, error, data}= useQuery(VIDEOS_RECOMMENDED);
    if(loading) return <p>loading...</p>;
    if (error) return `Error! ${error.message}`; 
    return (
        <div className="col-xl-4 col-lg-4 col-md-4" >
            <div className='nextVideo1 ms-2 ps-2 pt-2' > 
                {data.videosRecommended.map((video)=>{                     
                    return (                                  
                        <VideoCard
                            key={video.id}
                            title ={video.title}
                            username={video.user.username}
                            date={video.createdAt}
                            view={video.countView}
                            like={video.countLike}
                            videoId={video.id}
                            thumbnail={video.thumbnail}
                            avatarUser={video.user.avatar}
                            idUser={video.user.id}
                            nextVideo={true}
                        >
                        </VideoCard>
                    )
                })}
            </div>
        </div>
    )
})
