import WatchingVideoLeftBot from './wVideo_left_bot/WVideoLeftBot'
import React from 'react'
export default React.memo(function WatchingVideoLeft(props) {
  
    return (
        <div className="col-xxl-8 col-xl-8 col-lg-12 col-md-12 col-sm-12 mx-auto card  WatchingVideoLeft d-flex flex-column ">
            <video  controls className="videoPlay  mx-auto mt-2 p-2" autoPlay>
              <source className="embed-responsive-item " src={props.urlVideo} type="video/mp4" />
              <source className="embed-responsive-item " src={props.urlVideo} type="video/ogg" />
            </video>
            <WatchingVideoLeftBot></WatchingVideoLeftBot>
        </div>
    )
}
)