import React from 'react'
import TopManageUser from './topManageUser/TopManageUser'
import BodyManageUser from './bodyManageUser/BodyManageUser'
import { useAuthContext } from '../../context/AuthContext'
import NotificationError from '../NotificationError'

export default function ManageUser() {
    const {isAuthenticated} = useAuthContext()
    
    if(!isAuthenticated) 
    return (
        <NotificationError text ={'Hãy đăng nhập hoặc đăng ký để có thể tiếp tục.'}></NotificationError>
    )

    return (
        <div className="card-body row pt-0" >
            <div className="col-11 mx-auto">
                <TopManageUser/>
                <BodyManageUser/>
            </div>
        </div>
    )
}
