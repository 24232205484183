import React from 'react'

export default function NotificationError(props) {
  return (
    <div className="alert alert-dark  m-1"  role="alert">
        <div className='mx-auto'>
            {props.text}
        </div>
    </div>
  )
}
