import AWS from 'aws-sdk';

AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_IAM_REACT_UPLOAD_S3,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY_IAM_REACT_UPLOAD_S3,
    region: 'ap-southeast-1',
    signatureVersion: 'v4',
});

export const uploadImageToS3 = async(file, formListVideo, setFormListVideo) => {
  const s3 = new AWS.S3();
  if (!file) {
    return;
  }
  const params = { 
    Bucket: 'dbs3.learnlink', 
    Key: `${Date.now()}.${file.name}`, 
    Body: file 
  };
  const { Location } = await s3.upload(params).promise();

  setFormListVideo({
    ...formListVideo,
    thumbnail: Location
    })
    console.log(Location);
    
}