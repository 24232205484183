import { gql } from '@apollo/client'
const SIGNUP = gql `
mutation Signup (
    $email: String!
    $password: String!
    $name: String!
){
    signup(
        email: $email
        password: $password
        username: $name
    ){
        token
    }
}
`;

const LOGIN = gql `
mutation Login(
    $email: String!
    $password: String!
){
    login(email: $email, password: $password){
        token
        user{
            tokenVersion
        }
    }
}
`;
const LOGIN_WITH_GOOGLE = gql `
mutation loginWithGoogle( $jwtToken: String){
    loginWithGoogle( jwtToken: $jwtToken){
        token
        user{
            tokenVersion
        }
    }
}
`

const LOGOUT = gql `
mutation logout(
    $userId: String!
){
    logout(userId: $userId){
        tokenVersion
    } 
}
`

const CREATE_VIDEO = gql `
mutation createVideo(
    $title: String
    $description: String
    $url: String!
    $thumbnail: String!
    $document: String
    $videoListId: String
    $tags: String
){
    createVideo(title:$title, description:$description, url:$url, thumbnail: $thumbnail,
                document: $document, videoListId: $videoListId, tags: $tags){
            id
            videoListId
            title
            thumbnail
            document
            description
            createdAt
            countLike
            countView
            tags
       
    }
}
`;

const PUBLISH_VIDEO = gql `
mutation publishVideo(
    $id:ID!
    $title: String
    $description: String
    $url: String
    $thumbnail: String
    $document: String
    $userId: String
    ){
        publishVideo(id: $id, title: $title, description: $description, url: $url, thumbnail: $thumbnail, document: $document, userId: $userId){
        id
        title
        thumbnail
        document
        url
        description
        view{
            id
        }
        videoLikes{
            id
        }
       
        user{
        id
        username
        avatar
        }
        }
    }
`

const DELETE_VIDEO = gql `
mutation deleteVideo($id: ID!){
    deleteVideo(id: $id){
        id
        title
    }
}
`

const PUBLISH_USER = gql `
mutation publishUser(
    $username: String
    $avatar: String
    $cover: String
    $about: String
    $videoAbout: String
    $info: String

){
    publishUser(username:$username, avatar:$avatar, cover:$cover, about: $about, videoAbout: $videoAbout, info: $info){
        id
        avatar
        cover
        username
        about
        videoAbout
        info
        countSubscriber
        countSubscribedTo
    }
}
`;

const CREATED_VIEW = gql `
mutation created_view($videoId: String!){
    updateVideoView(videoId: $videoId){
        newView{
            id
            video{
                id
                title
                thumbnail
                createdAt
                countLike
                countView
                user{
                    id
                    avatar
                    username
                }
            }
        }
        
    }
}
`;

const NEW_COMMENT = gql `
mutation new_comment($videoId: String!, $text: String!){
    comment(videoId: $videoId, text: $text){
        id
        text
        createdAt
        user{
            id
            avatar
            username
        }
        parentCommentId
        parentComment{
            id
            text
        }
        childrenComments{
            id
            text
            parentCommentId
        }
    }

}
`;

const NEW_CHILDREN_COMMENT = gql `
mutation new_children_comment($videoId: String!, $text: String!, $parentCommentId: String!){
    childrenComment(videoId: $videoId, text: $text, parentCommentId: $parentCommentId){
        id
        text
        createdAt
        user{
            id
            avatar
            username
        }
        parentCommentId
        parentComment{
            id
            text
        }
        childrenComments{
            id
            text
            parentCommentId
        }
    }

}
`;

const USER_SUBSCRIPTION = gql `
mutation userSubscription($userId: ID!){
    userSubscription(userId: $userId){
        id
    }
}
`

const NEW_LIKE_VIDEO = gql `
    mutation updateVideoLike($videoId: String!){
        updateVideoLike(videoId: $videoId){
            videoLike{
                    id
                    video{
                        id
                        title
                        thumbnail
                        createdAt
                        countLike
                        countView
                        tags
                    }
                    user{
                        id
                        avatar
                        username
                    }
                }
            video{
                id
                createdAt  
                title
                document
                countLike
                countView
                tags
                user{
                    id
                    avatar
                }
            }
        }
    }
`

const NEW_LIST_VIDEO = gql `
mutation createVideoList($title: String!, $about: String,$thumbnail: String){
    createVideoList(title: $title, about: $about, thumbnail: $thumbnail){
        id
        title
        thumbnail
        about
        countVideo
        video{
            id
            createdAt
            title
            countLike
            countView
            thumbnail
            videoListId
        }
        
    }
}
`
const PUBLISH_VIDEO_LIST = gql `
mutation publishVideoList($id: ID!, $title: String, $about: String, $thumbnail: String){
    publishVideoList(id: $id, title: $title, about: $about, thumbnail: $thumbnail){
        id
        title
        thumbnail
        about
    }
}
`
const DELETE_VIDEO_LIST = gql `
mutation deleteVideoList($id: ID!){
    deleteVideoList(id: $id){
        id
    }
}
`
const UPDATE_NOTIFICATION = gql `
mutation updateNotification($notificationId: String){
    updateNotification(notificationId: $notificationId){
        id
        text
        read
        video{
            id
            thumbnail
        }
    }
}
`

export {
    SIGNUP,
    LOGIN,
    LOGIN_WITH_GOOGLE,
    LOGOUT,
    CREATE_VIDEO,
    PUBLISH_VIDEO,
    DELETE_VIDEO,
    PUBLISH_USER,
    CREATED_VIEW,
    NEW_COMMENT,
    NEW_CHILDREN_COMMENT,
    USER_SUBSCRIPTION,
    NEW_LIKE_VIDEO,
    NEW_LIST_VIDEO,
    PUBLISH_VIDEO_LIST,
    DELETE_VIDEO_LIST,
    UPDATE_NOTIFICATION
}