
import { NavLink } from 'react-router-dom'
import React from 'react'
import { useState, useEffect } from 'react';
export default function HeaderLeft() {
    // Trạng thái để lưu trữ src và className của logo
  const [logo, setLogo] = useState({
    src: '/FullLogo.jpg',
    className: 'full-logo'
  });
    // Sử dụng useEffect để kiểm tra kích thước màn hình khi trang tải
    useEffect(() => {
        const screenWidth = window.innerWidth;
        if (screenWidth <= 768) { // Nếu màn hình nhỏ hơn hoặc bằng 768px
          setLogo({
            src: '/IconOnly.png',
            className: 'logo'
          });
        } else { // Màn hình lớn hơn 768px
          setLogo({
            src: '/FullLogo.jpg',
            className: 'full-logo'
          });
        }
      }, []); // Chạy chỉ một lần khi component được render
    return (
        <li className=" nav-item d-flex align-items-center ms-3">
            <button className="noneBorderButton " data-bs-toggle="offcanvas" href="#offcanvasExample"  aria-controls="offcanvasExample">
                <i className="fas fa-bars a1 mx-t toggle" />
            </button>
            <div className="offcanvas offcanvas-start sideBarOffcanvas offcanvasFix" tabIndex={-1} id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <div className="offcanvas-header offcanvasHeader">
                    <img src="/FullLogo.jpg" className="full-logo " alt="linkLearn" />
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
                </div>
                <div className="offcanvas-body">
                    <div className="col-1 sideBar ">
                        <div className="navigation1">
                            <ul>
                                <li>
                                <NavLink to="/">
                                    <span className="Icon"><i className="fas fa-home" /></span>
                                    <span className="title" data-bs-dismiss="offcanvas">Trang chủ</span>
                                </NavLink>
                                </li>
                                <li>
                                <NavLink to="/manageUser">
                                    <span className="Icon"><i className="fas fa-user-tie" /></span>
                                    <span className="title" data-bs-dismiss="offcanvas" >Kênh của bạn</span>
                                </NavLink>
                                </li>
                                
                                <li>
                                <NavLink to="/videosWatched">
                                    <span className="Icon"><i className="fas fa-hourglass-start" /></span>
                                    <span className="title" data-bs-dismiss="offcanvas" >Video đã xem gần đây</span>
                                </NavLink>
                                </li>
                                <li>
                                <NavLink to="/videosLike">
                                    <span className="Icon"><i className="fas fa-user-clock" /></span>
                                    <span className="title" data-bs-dismiss="offcanvas" >Video yêu thích</span>
                                </NavLink>
                                </li>
                                <li>
                                <NavLink to="/userFollow">
                                    <span className="Icon"><i className="fas fa-calendar-check" /></span>
                                    <span className="title" data-bs-dismiss="offcanvas" >Kênh đã đăng ký</span>
                                </NavLink>
                                </li>
                            </ul>
                        </div> 
                    </div>
                </div>
            </div>
            <NavLink className="navbar-brand" to="/">
                <img  src={logo.src} className={logo.className} alt="linkLearn" />
            </NavLink>
        </li>
    )
}

