const config = {
    AUTH_TOKEN: 'auth-token',
    URL_WEB_SERVER: 'https://linklearn.vn/graphql',
    URL_WEB_SERVER_REALTIME: `wss://linklearn.vn/graphql`,
    URL_WEB_SERVER_ROUTER: 'https://linklearn.vn/refresh_token'
    // 
    // URL_WEB_SERVER: 'http://54.169.6.115/graphql',
    // URL_WEB_SERVER_REALTIME: `ws://54.169.6.115/graphql`,
    // URL_WEB_SERVER_ROUTER: 'http://54.169.6.115/refresh_token'
    //     // 
        // URL_WEB_SERVER: 'http://localhost:4000/graphql',
        // URL_WEB_SERVER_REALTIME: `ws://localhost:4000/graphql`,
        // URL_WEB_SERVER_ROUTER: 'http://localhost:4000/refresh_token'
};

export default config;