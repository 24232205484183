import { gql } from '@apollo/client';

const VIDEOS_RECOMMENDED = gql `
 query videosRecommended($skip: Int, $take: Int) {
    videosRecommended(skip: $skip, take: $take) {
         title
         id
         thumbnail
         createdAt
         countLike
         countView
         tags
         user{
             username
             avatar
             id
         }
     }
 }
`;

const URL_VIDEO_PLAYING = gql `
query videoPlaying($id:ID!) {
    video(id:$id){
        id      
        url
        videoListId
    }
}
`;
const COMMENTS_VIDEO = gql `
query commentsVideo($videoId: String, $take: Int){
    commentsVideo(videoId: $videoId, take: $take){
            id    
            createdAt
            text
            user{
                id
                avatar
                username
            }
            parentCommentId
            parentComment{
                id
                text
            }
            childrenComments{
                id
                text
                parentCommentId
                user{
                    id
                    avatar
                    username
                }
            }
            
    }
}
`;

const getIntroduceVideo = gql `
query getIntroduceVideo($id:ID!){
    video(id:$id){
        id
        description
        user{
            id
            username
            avatar
        }
        videoLikes{
            id
            createdAt
            like     
            userId   
            videoId 
        }
    }
}
`;

const getDateIntVideo = gql `
query getDateIntVideo($id:ID!){
    video(id:$id){
        id
        createdAt  
        title
        document
        countLike
        countView
        tags
        user{
            id
            avatar
        }
        
    }
}
`;
const CHECK_USER_LIKE_VIDEO = gql `
query checkUserLikeVideo($videoId:String!){
    checkUserLikeVideo(videoId: $videoId){
        id
        video{
            id
            countLike
        }
    }
}
`

const getDocumentVideo = gql `
query getDocumentVideo($id:ID!){
    video(id:$id){   
        id
        document  
    }
}
`;

const getUser = gql `
query getUser($id:ID!){
    user(id:$id){
        id
        username
        avatar       
        about
        videoAbout
        cover
        info
        countSubscriber
        countSubscribedTo
    }
}
`;
const LIST_VIDEO_USER = gql `
  query listVideoUser($idUser: String, $take: Int){
    listVideoUser(idUser: $idUser, take: $take){
        id
        title
        thumbnail
        about
        countVideo
        video{
            id
            createdAt
            title
            countLike
            countView
            thumbnail
            videoListId
            tags
        }
    }
  }
`
const LIST_VIDEO_USER_MANAGE = gql `
  query listVideoUserManage( $take: Int){
    listVideoUserManage( take: $take){
        id
        title
        thumbnail
        about
        countVideo
        video{
            id
            createdAt
            title
            countLike
            countView
            thumbnail
            videoListId
            tags
        }
    }
  }
`
const SHOW_VIDEO_LIST = gql `
query showVideoList($idVideoList: String, $take: Int){
    showVideoList(idVideoList: $idVideoList, take: $take){
        id
        id
        createdAt
        title
        countLike
        countView
        thumbnail
        videoListId
        tags
        
    }
}
`
const getCountUserSubscribedTo = gql `

query getUser($idUser:ID!){
    user(id:$idUser){
        id
        username
        cover
        avatar
        countSubscriber
        countSubscribedTo
    }
}
`

const GET_AVATAR_USER_MANAGE = gql `
query getAvatarUserManage{
    userManage{
        id
        avatar
        cover
        username
        about
        videoAbout
        info
        countSubscriber
        countSubscribedTo
    }
}
`;

const VIDEOS_SEARCH = gql `
query videosSearch($filter: String!){
    videosSearch(filter:$filter){
        usersSearch{
            id
            avatar
            username
            about
            countSubscriber
            countSubscribedTo
        }
        videosSearch{
            id
            title
            thumbnail
            countLike
            countView
            user{
                id
                avatar
                username
            }
          }
       
    }
}
`;

const VIDEOS_WATCHED = gql `
query videosWatched($skip: Int, $take: Int){
    videosWatched(skip: $skip, take: $take){
        id
        video{
            id
            title
            thumbnail
            createdAt
            countLike
            countView
            user{
                id
                avatar
                username
            }
        }
        
    }
}
`;

const VIDEOS_LIKE = gql `
query videosLike($skip: Int, $take: Int){
    videosLike(skip:$skip, take: $take){
        id
        video{
            id
            title
            thumbnail
            createdAt
            countLike
            countView
            tags
            
        }
        user{
            id
            avatar
            username
        }
    }
}
`

const USER_MANAGER_SUBSCRIBED_TO = gql `
query userManage{
    userManage{
        id
    	subscribers{
          id
          subscribedTo{
            id
            username
            avatar
            about
            countSubscribedTo
          }
        }

    }
}
`
const NOTIFICATIONS = gql `
query userManage{
    userManage{
        id
        notifications{
				id
                text
                read
                video{
                    id
                    thumbnail
                }
            }
    }
}
`

export {
    VIDEOS_RECOMMENDED,
    getDateIntVideo,
    getIntroduceVideo,
    getDocumentVideo,
    CHECK_USER_LIKE_VIDEO,
    getUser,
    LIST_VIDEO_USER,
    LIST_VIDEO_USER_MANAGE,
    SHOW_VIDEO_LIST,
    getCountUserSubscribedTo,
    GET_AVATAR_USER_MANAGE,
    VIDEOS_SEARCH,
    URL_VIDEO_PLAYING,
    COMMENTS_VIDEO,
    VIDEOS_WATCHED,
    VIDEOS_LIKE,
    USER_MANAGER_SUBSCRIBED_TO,
    NOTIFICATIONS,

}