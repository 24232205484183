import React from 'react'
import { useMutation, useQuery } from '@apollo/client';
import { NEW_LIKE_VIDEO } from '../../../../../graphql-client/Mutation';
import {CHECK_USER_LIKE_VIDEO, VIDEOS_LIKE} from '../../../../../graphql-client/Queries'
import { useParams } from 'react-router';
import { getDateIntVideo } from '../../../../../graphql-client/Queries';

export default function ButtonLikeVideo(props) {
    const {id}= useParams()
    const likeVideo = ()=>{
        created_like_video()
    }
    const [created_like_video] =  useMutation(NEW_LIKE_VIDEO,
        {
          variables: {videoId:id},
          update:(cache, {data})=>{
              cache.writeQuery({
                query:getDateIntVideo,
                data:{
                  __typename: 'Video',
                  video: data.updateVideoLike.video
                }
              });
              const videosLikeInCache = cache.readQuery({
                query:VIDEOS_LIKE
              },);
              
              if(videosLikeInCache){
                const videosLike =  data.updateVideoLike.videoLike.video
                                    ? [data.updateVideoLike.videoLike,...videosLikeInCache.videosLike]
                                    : videosLikeInCache.videosLike.filter(e => e.id !== data.updateVideoLike.videoLike.id);
                cache.writeQuery({
                  query: VIDEOS_LIKE,
                  data:{
                    videosLike: videosLike
                  }
                });
              }
              const videoNew = data.updateVideoLike.videoLike.video
                                ?
                                data.updateVideoLike.videoLike.video
                                :
                                {
                                  id:null,
                                  countLike:null
                                };
              cache.writeQuery({
                query:CHECK_USER_LIKE_VIDEO,
                variables: { videoId: id },
                data:{
                    checkUserLikeVideo:
                          { 
                            id: data.updateVideoLike.videoLike.id,
                            video: videoNew
                          }
                  }
              });
            },
        }
      );

    const handleLikeVideo =()=>{
      likeVideo();
    };

    const {data:videoLike, loading: loading1, error: error1} = useQuery(CHECK_USER_LIKE_VIDEO, 
        {
          variables: { videoId: id },
        }
      )
      if(loading1)return 'loading...'
      if(error1)return ' error'
     
    const dataCheckUserLike = videoLike.checkUserLikeVideo
  
    return (
            <div className="m-auto me-1 ms-1">
                <i 
                  className={
                      dataCheckUserLike !== null && dataCheckUserLike.video.id !== null
                      ?
                      "fas fa-heart"
                      :
                      "far fa-heart"
                   } 
                  onClick={()=>handleLikeVideo()}
                >
                </i>
            </div>
    )
}
