import React from 'react'
import VideoCard from './videoCard/VideoCard'
import { useQuery } from '@apollo/client'
import {VIDEOS_RECOMMENDED} from '../../../graphql-client/Queries'
import NotificationError from '../../NotificationError'

export default function HomeRight() {
    
    const {loading, error, data, fetchMore} = useQuery(VIDEOS_RECOMMENDED,
        { variables: { take: 6}, })
    if (loading) return (
        <div className="card" aria-hidden="true">
        <img src="..." className="card-img-top" alt="..." />
        <div className="card-body">
          <h5 className="card-title placeholder-glow">
            <span className="placeholder col-6" />
          </h5>
          <p className="card-text placeholder-glow">
            <span className="placeholder col-7" />
            <span className="placeholder col-4" />
            <span className="placeholder col-4" />
            <span className="placeholder col-6" />
            <span className="placeholder col-8" />
          </p>
        </div>
      </div>
    )
    if (error) return `Error! ${error.message}`;
    
    const onLoadMore = () => fetchMore({
        variables: {
            take:data.videosRecommended.length+6
        },
    })

    const handleScroll = ({ currentTarget }, onLoadMore) => {
        if (
            currentTarget.scrollTop + currentTarget.clientHeight >=
            currentTarget.scrollHeight
        ) {
          onLoadMore();
        }
      };
    return (
        <div className="col-xxl-10 col-xl-11 col-lg-11 col-mb-11 col-sm-12 videosRecommended " 
            onScroll={e => handleScroll(e, onLoadMore)} 
        >
            <div className="p-0 marginVideoRecommend" >
                <h5 className='ms-3 mt-1'>Video đề xuất</h5>
                {
                data.videosRecommended.length===0
                ?<NotificationError text={'Chưa có video nào.'}></NotificationError>
                :<div className="row ms-auto">
                    {           
                        data.videosRecommended.map((video) =>
                            {  
                                return (                                  
                                    <VideoCard
                                        key={video.id}
                                        title ={video.title}
                                        username={video.user.username}
                                        date={video.createdAt}
                                        view={video.countView}
                                        like={video.countLike}  
                                        videoId={video.id}
                                        avatarUser={video.user.avatar}
                                        idUser={video.user.id}
                                        thumbnail={video.thumbnail}
                                        tags={video.tags}
                                    >
                                    </VideoCard>
                                )
                            }
                        )
                    }
                </div>
                }
                    {
                    loading &&
                    <div className="card" aria-hidden="true">
                        <img src="..." className="card-img-top" alt="..." />
                        <div className="card-body">
                        <h5 className="card-title placeholder-glow">
                            <span className="placeholder col-6" />
                        </h5>
                        <p className="card-text placeholder-glow">
                            <span className="placeholder col-7" />
                            <span className="placeholder col-4" />
                            <span className="placeholder col-4" />
                            <span className="placeholder col-6" />
                            <span className="placeholder col-8" />
                        </p>
                        </div>
                    </div>
                    }
            </div>
        </div>
    )
}
