import { useState, useCallback } from 'react';

const useHover = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isButtonClick, setIsButtonClick] = useState(false);

    const onClose = () => setIsOpen(false)

    const handleButtonClick = useCallback(() => {
        setIsButtonClick(true);
    }, []);
    const handleResetButtonClick = useCallback(() => {
        setIsButtonClick(false);
    }, []);

    const handleMouseEnter = useCallback(() => {
        setIsOpen(true);
    }, []);

    const handleMouseLeave = useCallback(() => {
        if (!isButtonClick) {
            setIsOpen(false);
        }
    }, [isButtonClick]);

    return [isOpen, onClose, handleButtonClick, handleMouseEnter, handleMouseLeave, handleResetButtonClick];
};

export default useHover;