import HomeLeft from './home_left/HomeLeft'
import HomeRight from './home_right/HomeRight.jsx'
import React from 'react'

export default function Home() {
    return (
        <div className='row'>
            <HomeLeft></HomeLeft>
            <HomeRight></HomeRight>
        </div>
    )
}

