import React from 'react'

export default function ComponentUploadVideo({isLoading, process, setFile, formState}) {
      const handleFileVideoSelect = (e) => {
        setFile(e.target.files[0]);
      }
  return (
    <div className='p-2'>
    {
        isLoading
        ? 
        <div className="progress">
            <div className="progress-bar" role="progressbar" style={{width: process+'%'}} 
            aria-valuenow={process} aria-valuemin={0} aria-valuemax={100}
            >
                {process}%
            </div>
        </div>
        :
        (
            !formState.url 
            ?<div className="d-flex justify-content-between">
                <input type="file" accept="video/*" onChange={handleFileVideoSelect} />
            </div>
            :
            (
                formState.url && 
                <div className="alert alert-success d-flex align-items-center" role="alert">
                    <div>
                        Đã tải lên Video
                    </div>
                </div>
            )
        )
    }
    
</div>
  )
}
