import React from 'react'
import BodyUser from './bodyUser/BodyUser'
import TopUser from './topUser/TopUser'
import { useParams } from 'react-router-dom'
export default function User() {
    let {idUser}=useParams()
    return (
        <div className="card-body row pt-0" >
            <div className="col-11 mx-auto">
                <TopUser/>
                <BodyUser idUser={idUser}/>
            </div>
        </div>
    )
}
