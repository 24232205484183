
import { Link } from "react-router-dom";
import React  from 'react'
import moment from 'moment'
import {CREATED_VIEW} from '../../../../graphql-client/Mutation'
import { useMutation } from "@apollo/client";
import { useAuthContext } from "../../../../context/AuthContext";
import { VIDEOS_WATCHED } from "../../../../graphql-client/Queries";
export default React.memo(function VideoCard(props) {
    const {isAuthenticated} = useAuthContext();
    const newViewVideo = ()=>{
        if(isAuthenticated) created_view()
    }
   
    const [created_view, { loading, error }] = useMutation(CREATED_VIEW,{
        variables: {videoId: props.videoId},
        update(cache, {data}) {
            const videoWatchedInCache = cache.readQuery({
                query:VIDEOS_WATCHED
            },);
            if(videoWatchedInCache){
                cache.writeQuery({
                    query: VIDEOS_WATCHED,
                    data:{
                        videosWatched: [data.updateVideoView.newView,...videoWatchedInCache.videosWatched]
                    }
                })
            }
        }
    });
    if (loading) return 'Submitting...';
    if (error) return `Submission error! ${error.message}`;
  
    return (
        <div 
            className={ 
                props.nextVideo 
                ? 
                "col-12 me-auto" 
                :
                "col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-12 me-auto"
            }
        >
            <div className="videoCard m-1 p-1">
                <Link to = {"/watching/" + props.videoId} onClick={()=>newViewVideo()}>
                    <img   className="videoCard_thumbnail" src={props.thumbnail} alt="" />
                </Link>
                <div className="videoCard_info d-flex ">
                    {
                        props.avatarUser &&
                        <Link to = {"/user/"+ props.idUser}  >
                            <img  className="img-video_avatar rounded-circle" alt="" src={props.avatarUser} data-bs-dismiss="modal"/>
                        </Link>
                    }
                    <div className="videoCard_info_text">
                        <Link className="nameUser" to = {"/watching/" + props.videoId} onClick={()=>newViewVideo()}>
                            <b className="Title_video" data-bs-dismiss="modal">
                                {props.title}
                            </b>
                        </Link>
                        {
                            !props.publishVideoManager   && 
                            <Link to = {"/user/"+ props.idUser} className="nameUser">
                                <p className="mt-1 mb-1">{props.username}</p>
                            </Link>
                        }
                            <div className="d-flex justify-content-between">
                                {
                                   props.tags && props.tags.length >=1 && 
                                    <b className="mt-1 mb-1">{props.tags}</b>
                                }
                                {
                                    props.publishVideoManager && 
                                        <div className="btn-group dropup">
                                            <button type="button" className="btn rounded ps-1 pe-1" data-bs-toggle="dropdown" aria-expanded="true">
                                                <i className="fa fa-wrench" aria-hidden="true"></i>
                                            </button>
                                            <ul className="dropdown-menu">
                                                <li>
                                                    <button 
                                                            onClick={()=>props.isShowFormDeleteVideo()}
                                                            className="btn btn-light w-100 " data-bs-toggle="modal" type="button" 
                                                            data-bs-target={props.videoIdInFormDelete
                                                                ?"#deleteVideoManager"+ props.videoIdInFormDelete
                                                                : "#deleteVideoManager-default"}
                                                    >
                                                        <i className="fa fa-trash" aria-hidden="true"></i> Xóa video
                                                    </button>
                                                </li>
                                                <li>
                                                    <button 
                                                        onClick={()=>props.isShowFormUpdateVideo()}
                                                        className="btn btn-light w-100 mt-1" type="button" data-bs-toggle="modal" 
                                                        data-bs-target={
                                                            props.videoIdInFormState
                                                            ? "#publishVideoManager"+props.videoIdInFormState
                                                            : "#publishVideoManager-default"
                                                            } 
                                                        >
                                                        <i className="fas fa-pen" /> Sửa video
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                }
                            </div>
                        <div className="statisticalVideo d-flex">
                            <div className="d-flex">
                                <p>{props.view}</p>&nbsp;
                                <p>views</p>
                            </div>&nbsp;
                            <div >•</div>&nbsp;
                            <div className="d-flex">
                                <p>{props.like}</p>&nbsp;
                                <p>like</p>
                            </div>&nbsp;
                            <div >•</div>&nbsp;
                            <div className="d-flex">
                                {moment(props.date).fromNow() }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
)