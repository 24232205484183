import React from 'react'
import HomeLeft from '../home/home_left/HomeLeft'
import { VIDEOS_SEARCH } from '../../graphql-client/Queries'
import { useQuery } from '@apollo/client'
import { useParams, Link } from 'react-router-dom'
import VideoCard from '../home/home_right/videoCard/VideoCard'
import NotificationError from '.././NotificationError'
export default function VideosSearch(props) {
    const filter = useParams()
    const {data, loading, error} = useQuery(VIDEOS_SEARCH,{
        variables:filter
    })
    if(loading) return 'loading ...'
    if(error) return 'error ...'
    return (
        <div className="row">
            <HomeLeft></HomeLeft>
            <div className="col-xl-10 col-lg-12 col-mb-12 col-sm-12 mx-auto ">
                <div className="p-0 marginVideoRecommend">
                    {
                    data.videosSearch.usersSearch.length > 0
                    &&
                    <div className="row ms-auto mt-1">
                        <h5 className='mt-3 mb-1'>Tác giả: </h5 >
                            {data.videosSearch.usersSearch.map((user)=>{
                                return (
                                    <div className='col-xl-6 col-lg-6 col-mb-6 col-sm-6 d-flex flex-row mt-3 ' key={user.id}>
                                        <Link to={"/user/"+user.id}>
                                            <img 
                                                className='showListUser'
                                                src={user.avatar} 
                                                alt={user.username}
                                            />
                                        </Link>
                                        <div className='d-flex flex-column ms-3'>
                                            <Link to={"/user/"+user.id}>
                                                <h5 className='nameUserInLink'>{user.username}</h5>
                                            </Link>
                                            <p className='text_per_line m-1 p-1 ms-0 ps-0'>{user.about}</p>
                                            <div className="d-flex flex-row ">
                                                <p className='me-1'>
                                                    {user.countSubscribedTo}
                                                </p>
                                                <div>•</div>
                                                <p className='ms-1'>
                                                    Người đăng ký
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                })
                            }
                    </div>}
                    <h5 className='mt-3 mb-3 ps-3'>Video: </h5 >
                        {
                        data.videosSearch.videosSearch.length ===0
                        ?<NotificationError text={'Không tìm thấy Video mà bạn yêu cầu.'}></NotificationError>
                        :
                        <div className="row ms-auto mt-3">
                        { data.videosSearch.videosSearch.map((video)=>{
                                return (
                                    <VideoCard
                                    key={video.id}
                                    title ={video.title}
                                    username={video.user.username}
                                    date={video.createdAt}
                                    view={video.countView}
                                    like={video.countLike}  
                                    videoId={video.id}
                                    avatarUser={video.user.avatar}
                                    idUser={video.user.id}
                                    thumbnail={video.thumbnail}
                                    >
                                    </VideoCard>
                                    )
                                })
                            }
                        </div>
                        }
                </div> 
            </div>
        </div>
    )
}
