import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  
} from "react-router-dom";

import Header from "./component/nav/Header";
import { useAuthContext } from './context/AuthContext';
import DieuHuongUrl from "./router/DieuHuongUrl";
function App() {
  const [loading, setLoading] = useState(true)
  const {checkAuth} = useAuthContext()
 
  useEffect(() => {
    const authenticate = async ()=>{
      await checkAuth()
      setLoading(false)
    }
  
    authenticate()
  }, [checkAuth])
   
  if(loading) return (
    <div className='nav-item IconMenu d-flex   '>
      <i className="fas fa-bars a1 ms-3 me-2 align-self-center" />
      <div className="navbar-brand" >
        <img src="IconOnly.png" className="logo" alt="KingLearn" />
      </div>
    </div>
  )
  return (
    <Router >
            <Header></Header>
            <DieuHuongUrl ></DieuHuongUrl>
    </Router>
  );
}

export default App;
