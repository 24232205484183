import React from 'react'
import {NEW_LIST_VIDEO} from '../../../../../graphql-client/Mutation'
import { useMutation} from '@apollo/client';
import { useState } from 'react';
import { LIST_VIDEO_USER_MANAGE } from '../../../../../graphql-client/Queries';
import MakeImg from '../../../../../uploadToS3/MakeImg';
import { uploadImageToS3 } from '../../../../../uploadToS3/UploadImgToS3';
export default function FormNewListVideo() {
    const [formListVideo, setFormListVideo] = useState({
        title:'',
        about:'',
        thumbnail:'',
        isLoading: false,
      });
    const [croppedImage, setCroppedImage] = useState(null);

    const [createListVideo] = useMutation(NEW_LIST_VIDEO, {
        variables: {
          title:formListVideo.title,
          about:formListVideo.about,
          thumbnail: formListVideo.thumbnail
        },
       
        update: (cache, {data: {createVideoList}})=>{
                const data = cache.readQuery({
                    query: LIST_VIDEO_USER_MANAGE,
                    variables:{take: 4}
                });
               
                const newListVideoUser = [createVideoList,...data.listVideoUserManage]
                cache.writeQuery({
                    query: LIST_VIDEO_USER_MANAGE, 
                    variables:{take:4},
                    data:{
                        listVideoUserManage:newListVideoUser
                    }, 
                });
            },    
        },
        
        );
        const handleSubmitFormListVideo = async (e)=> {
            e.preventDefault();
            await uploadImageToS3(croppedImage, formListVideo, setFormListVideo);
            await createListVideo();
            setFormListVideo({...formListVideo, title:'', about:'',thumbnail:''});
            setCroppedImage(null);
            alert('Đã thêm Khóa Học mới thành công.')
          };
    return (
        <div className="modal-content ">
            <div className="modal-header  d-flex justify-content-between">
                <h5 className="modal-title" id="NewVideoListModalModalLabel">
                    Tạo khóa học mới.
                </h5>
                <i className="fa fa-times" aria-hidden="true" data-bs-dismiss="modal"></i>
            </div>
           
            <div className=' m-3 p-1'>
                 <label className="col-form-label">
                    Ảnh đại diện
                </label>
                <MakeImg 
                 croppedImage={croppedImage} setCroppedImage={setCroppedImage}
                ></MakeImg>
            </div>
            <form onSubmit={handleSubmitFormListVideo }>
                {/* <div className="modal-body"> */}
                    <div className="m-3 p-1">
                        <label htmlFor="titleNewVideoList-from" className="col-form-label">
                            Tên khóa học
                        </label>
                        <textarea className="form-control " id="titleNewVideoList-from" rows={1}  
                            value={formListVideo.title}
                            onChange={(e) =>
                                setFormListVideo({
                                ...formListVideo,
                                title: e.target.value
                            })
                        } 
                    />
                    </div>
                    <div className="m-3 p-1">
                        <label htmlFor="descriptionNewVideoList-from" className="col-form-label">
                            Miêu tả khóa học
                        </label>
                        <textarea className="form-control" id="descriptionNewVideoList-from" rows={5} 
                                value={formListVideo.about}
                                onChange={(e) =>
                                    setFormListVideo({
                                    ...formListVideo,
                                    about: e.target.value
                                })
                                } 
                        />
                    </div>
                    
                {/* </div> */}
                <div className="modal-footer">
                    <button type="button" className="btn btn-light" data-bs-dismiss="modal">Thoát</button>
                    <button type="submit" className="btn btn-success" 
                            data-bs-dismiss="modal"
                    >
                            Xuất bản
                    </button>
                </div>
            </form>
        </div>
    )
}
