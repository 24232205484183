import React,{useState} from 'react'
import VideoCard from '../../../home/home_right/videoCard/VideoCard';
import { SHOW_VIDEO_LIST } from '../../../../graphql-client/Queries';
import { useQuery } from '@apollo/client';

export default function ShowVideoInListVideo(props) {
    const upTakeOnFetchMore = 6;
    const beginTake = 6;
    const [buttonStatus, setButtonStatus] = useState({
      isCountVideoDoClick: beginTake,
      showButtonNewVideo: false,
      
  })
    const {data, loading, error, fetchMore} = useQuery(SHOW_VIDEO_LIST, {
      variables:{
        idVideoList: props.idVideoList,
        take: beginTake
      }
    })
    
    if(loading) return (
      <div className="card" aria-hidden="true">
      <img src="..." className="card-img-top" alt="..." />
      <div className="card-body">
        <h5 className="card-title placeholder-glow">
          <span className="placeholder col-6" />
        </h5>
        <p className="card-text placeholder-glow">
          <span className="placeholder col-7" />
          <span className="placeholder col-4" />
          <span className="placeholder col-4" />
          <span className="placeholder col-6" />
          <span className="placeholder col-8" />
        </p>
      </div>
    </div>
      )
    if(error) return console.error();

    const onLoadMore = () => {
      fetchMore({
      variables: {
          idVideoList: props.idVideoList,
          take: data.showVideoList.length+upTakeOnFetchMore
      },
    })}
    const showVideo = data && data.showVideoList 
  return (
      <div className="modal-content">
          <div className="modal-header  d-flex justify-content-between">
            <h5 className="modal-title">
                Danh sách Video
            </h5>
            <i className="fa fa-times" aria-hidden="true" data-bs-dismiss="modal"></i>
          </div>
            <div className="row mb-3 ms-3">
              {props.user=== true
              ?showVideo.map((video)=>{
                return(
                  <VideoCard
                      key={video.id}
                      publishVideoManager={false}
                      document={video.document}
                      description={video.description}
                      title ={video.title}
                      date={video.createdAt}
                      view={video.countView}
                      like={video.countLike}
                      videoId={video.id}
                      tags={video.tags}
                      thumbnail={video.thumbnail}
                  >
                  </VideoCard>
                )
              })
              :  showVideo.map((video)=>{
                  return(
                    <VideoCard
                        key={video.id}
                        publishVideoManager={true}
                        document={video.document}
                        description={video.description}
                        title ={video.title}
                        date={video.createdAt}
                        view={video.countView}
                        like={video.countLike}
                        videoId={video.id}
                        tags={video.tags}
                        thumbnail={video.thumbnail}
                        videoIdInFormState = {props.videoIdInFormState}
                        videoIdInFormDelete = {props.videoIdInFormDelete}
                        isShowFormUpdateVideo = {()=>props.handleShowFormUpdateVideo(true, video.id,  
                        video.title, video.document, video.description)}
                        isShowFormDeleteVideo=  { ()=> props.handleShowFormDeleteVideo(video.id, props.idVideoList)}
                    >
                    </VideoCard>
                  )
                })
             }
            </div>
             {
              showVideo.length===buttonStatus.isCountVideoDoClick && 
                <button className=' btn  ms-2' 
                onClick={()=>{
                    setButtonStatus({isCountVideoDoClick: buttonStatus.isCountVideoDoClick+upTakeOnFetchMore})// cộng :5 băng với số dữ liệu được lấy thêm 
                    onLoadMore()
                }}
                > 
                <b>
                    Xem thêm video
                </b>
                </button>
            }
      </div>
  )
}
