import { gql } from '@apollo/client'

const UPDATE_COMMENT = gql `
subscription notification($videoId: String){
    newComment(videoId: $videoId){
      comment{
                id    
                createdAt
                text
                user{
                    id
                    avatar
                    username
                }
                parentCommentId
                parentComment{
                    id
                    text
                }
                childrenComments{
                    id
                    text
                    parentCommentId
                    user{
                        id
                        avatar
                        username
                    }
                }
            }
              
    }
  }
`

const UPDATE_SUBSCRIBED = gql `
subscription newUserSubscription($userId : String){
    newUserSubscription(userId: $userId){
        subscriber{
            id
            subscribers{
              id
              subscribedTo{
                id
                username
                avatar
                about
                countSubscribedTo
               }
            }
        }
        subscribedTo{
            id
            username
            cover
            avatar
            countSubscriber
            countSubscribedTo
        }
    }
}
`
export {
    UPDATE_COMMENT,
    UPDATE_SUBSCRIBED
}