import { useMutation, useQuery} from '@apollo/client'
import React from 'react'
import { useParams } from 'react-router-dom'
import {NEW_COMMENT} from '../../../../../../../../graphql-client/Mutation'
import { useState } from 'react'
import { NEW_CHILDREN_COMMENT } from '../../../../../../../../graphql-client/Mutation'
import { GET_AVATAR_USER_MANAGE } from '../../../../../../../../graphql-client/Queries'

export default React.memo(function NewComment(props) {
    
    const {id} = useParams()
    const [formTextComment, setFormTextComment] = useState({text:''})
    const childrenComment = props.parentCommentId
    
    const [new_comment] = useMutation(NEW_COMMENT, 
        {
            variables:{
                videoId: id, 
                text: formTextComment.text,
            },
        }
    )
    
    const [new_children_comment]= useMutation(NEW_CHILDREN_COMMENT,
            {
                variables:{
                    videoId: id,  
                    text: formTextComment.text,
                    parentCommentId: props.parentCommentId
                },
            }
        )
    const handleSubmit = (e)=> {
        e.preventDefault();
        
        childrenComment 
        ?
        new_children_comment()
        :
        new_comment();

        setFormTextComment({
            ...formTextComment,text:""
            })
        };
    const { data, error, loading } = useQuery(GET_AVATAR_USER_MANAGE);
    if(loading) return <p>loading...</p>;
    if(error) return <p>error</p>
    
    return (
        <div className="card-footer py-3 border-0" style={{backgroundColor: '#f8f9fa'}} id = {childrenComment? childrenComment:""}>
            <div className="d-flex flex-start w-100">
                <img className="rounded-circle shadow-1-strong me-3 avatarComment" src={data.userManage.avatar} 
                        alt="avatar" width={40} height={40} 
                />
                <form onSubmit={handleSubmit} className="form-outline w-100">
                    <textarea 
                            className="form-control" id="textAreaExample" rows={4} style={{background: '#fff'}} 
                            placeholder="Đặt câu hỏi hoăc bình luận của bạn..."
                            value={formTextComment.text}
                            onChange={(e) =>
                                setFormTextComment({
                                    ...formTextComment,text:e.target.value
                                })
                            }
                    />
                    <div className={formTextComment.text === '' ? "float-end mt-2 pt-1 d-none":"float-end mt-2 pt-1"}>
                        <button type= "submit" className="btn btn-primary btn-sm">Gửi</button>
                        <button type="button" className="btn btn-outline-primary btn-sm ms-1" onClick={()=>setFormTextComment({text:''})}>Hủy</button>
                    </div>
                </form>
            </div>
        </div>
        )
}
)