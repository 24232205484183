import { useMutation } from '@apollo/client'
import React from 'react'
import { PUBLISH_USER } from '../../../../graphql-client/Mutation'
import { useState } from 'react'
import AvatarForm from './settingForm/AvatarForm'
import CoverForm from './settingForm/CoverForm'
import {GET_AVATAR_USER_MANAGE} from '../../../../graphql-client/Queries'

export default React.memo(function Setting(props) {
  const [fromSettingUser, setFromSettingUser] = useState({
    fromName:'',
    fromAvatar:'', 
    fromCover:'',
    });
    
 
  const [settingUser] = useMutation(PUBLISH_USER,
    {
      variables:{
        username:fromSettingUser.fromName,
      },
      update:(cache, {data: usernameNew})=>{
        cache.writeQuery({
          query:GET_AVATAR_USER_MANAGE,
          data:{
            userManage: usernameNew.publishUser
          }
        });
      },
    }
   );
   console.log("setting manage 2");
   
  return (
          <div className="row ">
            <div className="col-xl-10 col-lg-10 col-mb-12 col-sm-12 mx-auto" >
              <div className="form-group m-1">
                  <form action="submit"
                    onSubmit={(e) => {
                      e.preventDefault();
                      settingUser();
                      setFromSettingUser({fromName:''})
                      alert('Đã cập nhật thành công')
                    }}
                  >
                  <h3 className='d-flex justify-content-center'>
                    Thông tin cá nhân
                  </h3>
                  <hr />
                  <label htmlFor="inputName">
                    <b className='m-1 p-1'>
                      Tên kênh: {props.nameManager}
                    </b>
                  </label>

                  <input 
                      type="text" name="inputName" id="inputName" className="form-control m-1  p-2" 
                      placeholder="Nhập vào tên mới của kênh" 
                      aria-describedby="helpId" 
                      value={fromSettingUser.fromName}
                      onChange={(e) =>
                        setFromSettingUser({
                          ...fromSettingUser,
                          fromName: e.target.value
                        })}
                   />
                  <button type="submit" className="btn btn-primary mt-1 ms-1">
                    Cập nhật
                  </button>
                </form>
                <hr />
                <AvatarForm 
                  avatarManager = {props.avatarManager}
                >
                </AvatarForm>
                <hr />
                <CoverForm 
                  coverManager = {props.coverManager}
                >
                </CoverForm>
              </div>
            </div>
          </div>
    )
})
