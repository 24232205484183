import React, { useState, useCallback } from 'react'
import ManageListVideo from './manageListVideo/ManageListVideo'
import ManageProfile from './manageProfile/ManageProfile'
import Setting from './manageSetting/Setting'
import { GET_AVATAR_USER_MANAGE } from '../../../graphql-client/Queries'
import { useQuery } from '@apollo/client'
// Ghi nhớ các component con để tránh re-render không cần thiết
const MemoizedManageListVideo = React.memo(ManageListVideo);
const MemoizedManageProfile = React.memo(ManageProfile);
const MemoizedSetting = React.memo(Setting);

export default function BodyManageUser() {
    const [status, setStatus] = useState(1);

    // Ghi nhớ kết quả truy vấn để tránh refetch trên mỗi lần render
    const { loading, error, data } = useQuery(GET_AVATAR_USER_MANAGE);

    // Ghi nhớ các hàm xử lý sự kiện để tránh tạo lại chúng trên mỗi lần render
    const handleProfileClick = useCallback(() => setStatus(1), []);
    const handleVideoListClick = useCallback(() => setStatus(2), []);
    const handleSettingsClick = useCallback(() => setStatus(3), []);

    if (loading) return <p>Loading...</p>;
    if (error) return 'Error';

    return (
        <div>
            <div className="bodyUser">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button 
                            className={`nav-link ${status === 1 ? 'active' : ''}`} 
                            id="profile-tab" 
                            data-bs-toggle="tab" 
                            type="button" 
                            role="tab" 
                            aria-controls="profile" 
                            aria-selected={status === 1}
                            onClick={handleProfileClick}
                        >
                            Giới thiệu
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button 
                            className={`nav-link ${status === 2 ? 'active' : ''}`} 
                            id="videoList-tab" 
                            data-bs-toggle="tab" 
                            type="button" 
                            role="tab" 
                            aria-controls="videoList" 
                            aria-selected={status === 2}
                            onClick={handleVideoListClick}
                        >
                            Khóa học
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button 
                            className={`nav-link ${status === 3 ? 'active' : ''}`} 
                            id="settings-tab" 
                            data-bs-toggle="tab" 
                            type="button" 
                            role="tab" 
                            aria-controls="settings" 
                            aria-selected={status === 3}
                            onClick={handleSettingsClick}
                        >
                            Cài đặt
                        </button>
                    </li>
                </ul>
                <div className="tab-content">
                    <div className={`tab-pane ${status === 1 ? 'show active' : ''}`} id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        {status === 1 && <MemoizedManageProfile />}
                    </div>
                    <div className={`tab-pane ${status === 2 ? 'show active' : ''}`} id="videoList" role="tabpanel" aria-labelledby="videoList-tab">
                        {status === 2 && <MemoizedManageListVideo />}
                    </div>
                    <div className={`tab-pane formSetting ${status === 3 ? 'show active' : ''}`} id="settings" role="tabpanel" aria-labelledby="settings-tab">
                        {status === 3 && 
                            <MemoizedSetting 
                                nameManager={data.userManage.username} 
                                avatarManager={data.userManage.avatar} 
                                coverManager={data.userManage.cover}
                                status={status}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
