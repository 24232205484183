
import CardIntVideoPlaying from './CardIntVideoPlaying'
import SupportVideoPlaying from './supportVideo/SupportVideo'
import React from 'react'

export default React.memo(function WVideoLeftBot() {
    
    return (
            <div>
                <CardIntVideoPlaying></CardIntVideoPlaying>
                <SupportVideoPlaying></SupportVideoPlaying>
            </div>
        )
}
)