import React from 'react'
import { useQuery } from '@apollo/client';
import { getDocumentVideo } from '../../../../../../graphql-client/Queries';
import { useParams } from 'react-router-dom';
import NotificationError from '../../../../../NotificationError';
export default React.memo(function DocumentVideoPlaying() {

     let {id} = useParams();
     const {loading, error, data} = useQuery(getDocumentVideo,{
         variables: {id},
     });
     if(loading) return <p>loading...</p>;
     if (error) return `Error! ${error.message}`;
     
    return (
        <div className="tab-pane documentVideo" id="document" role="tabpanel" aria-labelledby="document-tab-document">
            <div className='card card-body d-flex-column bd-highlight border-0 row col-11 '>
                {
                    data.video.document
                    ?
                    <p className="text_per_line m-1">
                        {data.video.document}
                    </p>
                    :
                    <NotificationError tex={"Video chưa có tài liệu nào."}></NotificationError>
                    
                }
            </div>
        </div>
    )
}
)