import AWS from 'aws-sdk';

AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_IAM_REACT_UPLOAD_S3,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY_IAM_REACT_UPLOAD_S3,
    region: 'ap-southeast-1',
    signatureVersion: 'v4',
});

export const UploadVideoToS3 = async(file, formState, setFormState, setIsLoading, setProcess) => {

    setIsLoading(true); // Đánh dấu là đang tải lên
    const s3 = new AWS.S3();
    if (!file) {
        return;
    }
    const videoParams = {
        Bucket: 'dbs3.learnlink',
        Key: `${Date.now()}.${file.name}`,
        Body: file,
    };
    const videoUpload = s3.upload(videoParams);
    videoUpload.on('httpUploadProgress', function(event) {
        var progress = Math.round((event.loaded / event.total) * 100);
        setProcess(progress);
    });

    try {
        const { Location: videoLocation } = await videoUpload.promise();
        setFormState({
            ...formState,
            url: videoLocation,
        });
        console.log('Video uploaded to S3:', videoLocation);
        console.log('Video uploaded to S3:', formState.url);
    } catch (error) {
        console.error('Error uploading video to S3:', error);
    }

    setIsLoading(false); // Hoàn thành tải lên, đặt lại trạng thái isLoading
};